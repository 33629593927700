.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;
}

.title {
  max-width: 450px;
  font-family: var(--headings-font);
  font-size: 35px;
  font-weight: 700;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 100px;
  }

  .title {
    font-size: 30px;
  }

  .image {
    width: 170px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 40px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }
}

@media (max-width: 575px) {
  .root {
    align-items: flex-start;
    flex-direction: column;
    gap: 35px;
  }

  .image {
    width: 195px;
    margin-left: auto;
    margin-right: auto;
  }
}