.root {
  padding-top: 14px;
  padding-bottom: 60px;
}

.marquee {
  --gap: 45px;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee::before,
.marquee::after {
  content: '';
  position: absolute;
  top: 0;
  width: 284px;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.marquee::before {
  left: 0;
  background: linear-gradient(270deg, transparent 0%, var(--body-bg) 95%);
}

.marquee::after {
  right: 0;
  background: linear-gradient(90deg, transparent 0%, var(--body-bg) 95%);
}

.marqueeContent {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: scroll-x 60s linear infinite;
}

/* .marquee:hover .marqueeContent {
  animation-play-state: paused;
} */

.icon {
  height: 52px;
}

.title {
  margin-bottom: 25px;
  font-weight: 600;
  text-align: center;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 1280px) {
  .root {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  .title {
    font-size: 16px;
  }

  .marquee::before,
  .marquee::after {
    width: 100px;
  }
}
