.root {
  padding: 120px 0;
  overflow: hidden;
}

.container {
  position: relative;
}

.container::before,
.container::after {
  content: '';
  position: absolute;
  top: -80px;
  background: url('/images/mini_buisness/tariffs-bg.svg') no-repeat;
  width: 220px;
  height: 780px;
  z-index: 1;
}

.container::before {
  left: -165px;
}

.container::after {
  right: -165px;
  transform: scaleX(-1);
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
  text-align: center;
}

.headerButtons {
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
  gap: 5px;
}

.headerButton {
  min-height: 44px;
  min-width: 250px;
  padding: 10px;
  font-family: var(--headings-font);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  background: none;
  border-radius: var(--border-radius-s);
}

.headerButton.active {
  color: var(--primary-color);
  box-shadow: var(--box-shadow-a);
  background: var(--color-white-primary);
  font-weight: 600;
}

.content {
  display: flex;
  justify-content: center;
}

.tariffs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  z-index: 2;
}

.headerButtonDiscount {
  margin-left: 10px;
}

.discountLabel {
  display: inline-block;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  color: var(--primary-color);
  background: var(--color-green-4);
  border-radius: var(--border-radius-s);
}

@media (max-width: 1280px) {
  .root {
    padding: 90px 0;
  }
}

@media (max-width: 1024px) {
  .root {
    padding: 60px 0;
  }

  .container::before,
  .container::after {
    top: -40px;
    width: 180px;
    background-size: 100%;
  }

  .header {
    margin-bottom: 30px;
  }

  .headerButton {
    min-width: 220px;
  }

  .tariffs {
    gap: 10px;
  }
}

@media (max-width: 800px) {
  .headerButton {
    min-width: 180px;
  }
}

@media (max-width: 575px) {
  .root {
    padding: 50px 0;
  }

  .container::before,
  .container::after {
    display: none;
  }

  .header {
    margin-bottom: 20px;
  }

  .headerButtons {
    width: 100%;
    gap: 5px;
  }

  .headerButton {
    width: 100%;
    min-width: 0;
    padding: 10px 5px;
    font-size: 16px;
  }

  .tariffs {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
