.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 366px;
  padding: 35px;
  border-radius: var(--border-radius-m);
  background: var(--color-white-primary);
  border: 1px solid var(--grey-4);
  gap: 25px;
}

.title {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 1.87rem;
  line-height: 1.32;
  color: var(--primary-color);
}

.description {
  font-size: 0.9375rem;
  line-height: 1.32;
  font-weight: 600;
  color: var(--grey-1);
}

.priceCalc {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.25;
}

.prices {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  gap: 0 10px;
}

.price {
  display: flex;
  align-items: center;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.32;
  text-transform: lowercase;
}

.priceDuration {
  margin-left: 6px;
  font-size: 0.9375rem;
  line-height: 16px;
  font-weight: 400;
  color: var(--grey-2);
}

.oldPrice {
  margin-top: 2px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 15px;
  text-decoration: line-through;
  color: var(--grey-2);
}

.economy {
  margin-top: 5px;
  font-size: 0.9375rem;
  line-height: 1.32;
  color: var(--grey-2);
}

.buttons {
  margin-top: auto;
}

.button {
  justify-content: space-between;
  padding: 9px 16px;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: var(--border-radius-s);
  box-shadow: 0 0 1px 1px var(--grey-5);
  gap: 5px;
}

.buttonIcon {
  width: 16px;
  height: 16px;
}

.notice {
  margin-top: 10px;
  font-size: 0.9375rem;
  line-height: 1.2;
  color: var(--grey-2);
}

.additional {
  display: flex;
  align-items: center;
  gap: 10px;
}

.additionalTitle {
  font-weight: 600;
  font-size: 14px;
}

.additionalSubtitle {
  margin-top: 5px;
  color: var(--grey-1);
}

.additionalValue {
  display: inline-block;
  min-width: 25px;
}

.additionalCount {
  flex-shrink: 0;
}

.additionalCount > div,
.additionalCount button {
  border-radius: var(--border-radius-s);
}

.additionalCount > div,
.additionalCount button,
.additionalCount input {
  background: var(--body-bg);
}

.additionalCount input {
  font-weight: 600;
  font-size: 1rem;
}

.additionalDescr {
  font-size: 12px;
  line-height: 1.3;
  color: var(--grey-2);
}

@media (max-width: 1024px) {
  .root {
    padding: 20px;
  }

  .title {
    font-size: 1.5rem;
  }

  .price {
    font-size: 1.4rem;
  }

  .additional {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .root {
    padding: 15px;
  }

  .advantages {
    height: auto;
    margin-right: 0;
  }

  .advantage {
    width: 100%;
  }

  .additional {
    gap: 5px 40px;
  }
}

@media (max-width: 575px) {
  .root {
    max-width: 100%;
    padding: 35px 30px;
    gap: 25px;
  }

  .title {
    font-size: 30px;
  }

  .price {
    font-size: 28px;
  }

  .description,
  .priceDuration,
  .oldPrice,
  .economy,
  .notice {
    font-size: 15px;
  }

  .button {
    font-size: 15px;
  }
}
