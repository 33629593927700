.root {
  margin-bottom: 80px;
}

.content {
  display: flex;
  gap: 20px;
}

.title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--headings-font);
}

.nav {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  gap: 11px;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 14px 3px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  transition: all var(--transition);
  cursor: pointer;
}

.navItemIcon {
  flex-shrink: 0;
  width: 62px;
  height: 62px;
}

.navItemText {
  padding-right: 10px;
  flex-grow: 1;
  font-weight: 600;
  line-height: 1.2;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-m);
  border: 1px solid var(--primary-color);
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 150px;
  }

  .content {
    gap: 14px;
  }

  .nav {
    width: 202px;
  }

  .navItem {
    padding: 4px 2px;
  }

  .navItemIcon {
    width: 63px;
    height: 65px;
  }

  .navItemText {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-direction: column;
    margin-bottom: 94px;
  }

  .content {
    flex-direction: column;
  }

  .title {
    margin-bottom: 15px;
  }

  .nav {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px 8px;
  }

  .navItem {
    flex-direction: column;
    max-width: 130px;
    padding: 0;
    background: none;
    text-align: center;
    gap: 5px;
  }

  .navItemText {
    padding: 0;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }

  .title {
    margin-bottom: 10px;
    max-width: 465px;
    font-size: 20px;
    line-height: 1.25;
  }
}

@media (max-width: 575px) {
  .navItemIcon {
    width: 48px;
  }

  .navItem {
    max-width: 112px;
  }

  .navItemText {
    font-size: 12px;
    font-weight: 500;
  }
}
