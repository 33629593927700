.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 170px;
  /* padding: 85px; */
  border-radius: var(--border-radius-l);
  background: var(--color-green-4);
  text-align: center;
}

.form {
  margin: 0;
  padding: 90px 20px;
}

.title {
  max-width: 600px;
  margin-bottom: 30px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 90px;
    /* padding: 58px 58px 70px; */
  }

  .form {
    padding: 60px 20px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 90px;
    /* padding: 40px 25px 44px; */
  }

  .form {
    padding: 30px 20px;
  }
}
