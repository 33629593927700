.root {
  margin-bottom: 160px;
}

.title {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

.review {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  background: var(--color-white-primary);
  border: 1px solid var(--color-white-primary);
  border-radius: var(--border-radius-m);
  padding: 35px;
}

.reviewMessage {
  margin-bottom: 20px;
  color: var(--grey-1);
  line-height: 1.1;
  font-size: 20px;
}

.author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: auto;
  border-top: 1px solid var(--grey-4);
  gap: 15px;
}

.authorImage {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.authorName {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
}

.authorPosition {
  margin-top: 5px;
  color: var(--grey-1);
  font-size: 20px;
  line-height: 1.1;
}


.slider {
  position: relative;
}

.slider :global(.slick-list) {
  margin-left: -10px;
  margin-right: -10px;
}

.slider :global(.slick-track) {
  display: flex;
}

.slider :global(.slick-slide) {
  height: inherit;
  padding-left: 10px;
  padding-right: 10px;
}

.slider :global(.slick-slide) > div {
  height: 100%;
}

.slider :global(.slick-current) .review {
  border: 1px solid var(--primary-color);
  background: var(--color-green-4);
}

.sliderButton {
  position: absolute;
  top: 50%;
  min-height: 40px;
  padding: 11px;
  line-height: 0;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--grey-4);
  transform: translateY(-50%);
  z-index: 1;
}

.sliderButton.prev {
  left: -20px;
}

.sliderButton.next {
  right: -20px;
}

.sliderButton:hover {
  background: var(--grey-5);
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 140px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 70px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 90px;
  }

  .slider {
    margin-left: calc(var(--container-padding)*-1);
    margin-right: calc(var(--container-padding)*-1);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    overflow: hidden;
  }

  .slider :global(.slick-list) {
    width: 93%;
    margin: 0 -8px;
    overflow: visible;
  }

  .slider :global(.slick-slide) {
    padding: 0 8px;
  }

  .reviewMessage {
    font-size: 16px;
  }

  .authorName {
    font-size: 18px;
  }

  .authorPosition {
    font-size: 16px;
  }

  .authorImage {
    width: 43px;
    height: 43px;
  }
}
